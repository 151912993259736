import request from '@/utils/request'

// 获取新的验证码id
export function newCaptcha() {
  return request({
    url: `/captcha/new`,
    method: 'get'
  })
}

// 根据验证码id刷新验证码
export function reloadCaptcha(id) {
  return request({
    url: `/captcha/${id}/reload`,
    method: 'get'
  })
}

// 获取短信验证码
export function sendSmsCaptcha(data) {
  return request({
    url: `/sms_captcha/send`,
    method: 'post',
    data
  })
}

// 用户验证手机号时发送短信验证码
export function sendAuthenticationSmsCaptcha() {
  return request({
    url: `/sms_captcha/authentication/send`,
    method: 'post'
  })
}

// 验证短信验证码是否正确
export function verifySmsCaptcha(data) {
  return request({
    url: `/sms_captcha/verify`,
    method: 'post',
    data
  })
}

// 用户验证手机号时 验证短信验证码是否正确
export function verifyAuthenticationSmsCaptcha(data) {
  return request({
    url: `/sms_captcha/authentication/verify`,
    method: 'post',
    data
  })
}

// 绑定用户邮箱前发送邮件验证码
export function sendEmailCaptchaBeforBind(data) {
  return request({
    url: `/email_captcha/before_bind/send`,
    method: 'post',
    data
  })
}
