<template>
  <div>
    <a-modal
      v-model="isShow"
      width="300px"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="绑定手机"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <a-input
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入手机号码' },
                { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确' }
              ],
              validateTrigger: 'blur'
            }]"
            placeholder="请输入手机号"
          />
        </a-form-item>

        <a-form-item>
          <a-row :gutter="8">
            <a-col :span="13">
              <a-input
                placeholder="请输入验证码"
                v-decorator="['sms_captcha', {
                  normalize: this.$lodash.trim,
                  rules: [{ required: true, message: '请输入验证码' }]
                }]"
              />
            </a-col>
            <a-col :span="11">
              <a-button
                type="primary"
                :block="true"
                class="simiot-register-form-captcha-button"
                @click="getSmsCaptcha"
                :disabled="disabled"
              >
                {{ btnTitle }}
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { sendSmsCaptcha } from '@/api/captcha'
import { updateUserSelfPhoneNumber } from '@/api/user'
export default {
  name: 'EditUserSelfPhoneNumber',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    identification: {
      type: String,
      required: true
    },
    nextVerifyId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'edit_full_name' }),
      submitting: false,
      disabled: false,
      btnTitle: '获取验证码'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    getSmsCaptcha(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields(['phone_number'], (err, values) => {
        if (!err) {
          if (values) {
            this.submitting = true
            sendSmsCaptcha(values).then((res) => {
              if (res.code === 0) {
                this.validateBtn()
              }
              this.submitting = false
            })
            // 去除手机号码唯一性校验
            // verifyPhoneNumber(values).then((res) => {
            //   if (res.code === 0) {
            //     this.submitting = true
            //     sendSmsCaptcha(values).then((res) => {
            //       if (res.code === 0) {
            //         this.validateBtn()
            //       }
            //       this.submitting = false
            //     })
            //   }
            // })
          }
        }
      })
    },

    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.disabled = false
          this.btnTitle = '获取验证码'
        } else {
          this.btnTitle = time + '秒后重试'
          this.disabled = true
          time -= 1
        }
      }, 1000)
    },

    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            sms_captcha: this.form.getFieldValue('sms_captcha'),
            next_verify_id: this.nextVerifyId
          }
          updateUserSelfPhoneNumber(this.id, data).then((res) => {
            if (res.code === 0) {
              // 告知父组件已完成
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
